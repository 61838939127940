<template>
  <div class="collection h-full flex flex-col">
    <div class="flex justify-between flex-row-center">
      <el-input
        class="mb-8"
        style="width:28%;"
        size="mini"
        placeholder="请输入配件名称、编码、品牌、生产码等关键字"
        v-model="keyword"
      >
        <i
          slot="suffix"
          class="el-input__icon el-icon-search cursor-pointer"
          @click="onSearch"
        ></i>
      </el-input>
      <div class="space-x-8">
        <span class=" cursor-pointer text-warning " @click="clearFn">
          一键清空
        </span>
        <span class=" cursor-pointer text-warning " @click="delShelfFn">
          删除已下架的商品
        </span>
      </div>
    </div>
    <!-- <div class="relative"> -->
    <div class="mb-8">
      <el-checkbox v-model="filterOff" @change="removedShelfChange"
        >屏蔽已下架的商品</el-checkbox
      >
      <!-- <el-checkbox v-model="filterNoPrice">只看有价格的商品</el-checkbox>
        <el-checkbox v-model="filterNoStock">只看有库存的商品 </el-checkbox> -->
    </div>
    <div class="flex-1" style="overflow:auto;">
      <template v-for="(item, index) in list">
        <GoodsItem
          :dataObj="item.goodsInfo"
          :createdAt="item.createdAt"
          :key="index"
        >
          <template slot="btn">
            <ChatDialog :storeId="item.storeId" :goodsInfo="item.goodsInfo" @click.stop.native />
            <i
              class="iconfont icon-line_home icon_btn cursor-pointer"
              @click.stop="toStore(item)"
            ></i>
            <i
              class="iconfont icon-line_delete icon_btn_del cursor-pointer"
              @click.stop="deleteFn(item)"
            ></i>
          </template>
        </GoodsItem>
      </template>
      <div class="text-center text-black-200" v-if="!list.length">暂无数据</div>
    </div>
    <!-- </div> -->
    <el-pagination
      class="mt-8"
      style="text-align:right;"
      layout="total,prev, pager, next"
      :current-page="pageInfo.page"
      :page-size="pageInfo.pageSize"
      :total="pageInfo.totalSize"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    >
    </el-pagination>
  </div>
</template>
<script>
import {
  getCollectibleListApi,
  delCollectibleGoodsApi,
} from "@/api/collection";
import GoodsItem from "@/components/userCenter/goodsItem.vue";
import ChatDialog from "../components/chatDialog.vue";

export default {
  components: {
    GoodsItem,
    ChatDialog
  },
  data() {
    return {
      keyword: "",
      filterOff: false, //屏蔽已下架的商品
      filterNoPrice: false, //只看有价格的商品
      filterNoStock: false, //只看有库存的商品
      list: [],
      pageInfo: {
        page: 1,
        pageSize: 20,
        totalSize: 0,
      },
    };
  },
  mounted() {
    this.loadList();
  },
  methods: {
    toStore(item) {
      let obj = {
        path: "/home/store",
        query: {
          storeId: item.storeId,
        },
      };
      this.$router.push(obj);
    },
    onSearch() {
      setTimeout(() => {
        this.loadList();
      }, 200);
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    loadList() {
      let params = {
        page: this.pageInfo.page,
        pageSize: this.pageInfo.pageSize,
        keyword: this.keyword,
        filterOff: this.filterOff,
        // filterNoPrice: this.filterNoPrice,
        // filterNoStock: this.filterNoStock
      };

      getCollectibleListApi(params).then((res) => {
        if (res) {
          this.list = res.rows || [];
          this.pageInfo.totalSize = res.totalSize;
        }
      });
    },
    handleSizeChange(val) {
      this.pageInfo.pageSize = val;
      this.pageInfo.page = 1;
      this.loadList();
    },
    handleCurrentChange(val) {
      this.pageInfo.page = val;
      this.loadList();
    },
    clearFn() {
      let _this = this;
      _this
        .$confirm("确定要删除所有商品收藏?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          let params = {
            type: "all",
            // id: 0
          };
          _this.delCollectionFn(params);
        })
        .catch(() => {
          // on cancel
        });
    },
    delShelfFn() {
      let _this = this;
      _this
        .$confirm("确定要删除已下架商品收藏?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          let params = {
            type: "off",
            // id: 0
          };
          _this.delCollectionFn(params);
        })
        .catch(() => {
          // on cancel
        });
    },
    deleteFn(item) {
      let _this = this;
      _this
        .$confirm("确定要删除该收藏商品?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          let params = {
            type: "specified",
            id: item.id,
          };
          _this.delCollectionFn(params);
        })
        .catch(() => {
          // on cancel
        });
    },
    delCollectionFn(params) {
      delCollectibleGoodsApi(params).then(() => {
        this.$message.success("删除成功");
        this.loadList();
      });
    },
    removedShelfChange() {
      this.loadList();
    },
  },
};
</script>
<style lang="less" scoped>
.collection {
  .icon_btn {
    padding: 4px;
    background: #ffeeeb;
    border-radius: 50%;
    font-size: 16px;
    color: #ff6e4c;
    cursor: pointer;
  }
  .icon_btn_del {
    padding: 4px;
    background: #f4f4f4;
    border-radius: 50%;
    font-size: 16px;
    color: #999999;
    cursor: pointer;
  }
}
</style>
